import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { ICourse, IStudent } from './types/types';
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loginToken: string;
  studentId: number;
  signedUpCourses: ICourse[];
  studentsOnCourseModalVisible: boolean;
  studentList: IStudent[] | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PerformanceTrackerBasicUserCoursesScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSignedUpCoursesApiCallId: string = '';
  getStudentListApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loginToken: '',
      studentId: -1,
      signedUpCourses: [],
      studentsOnCourseModalVisible: false,
      studentList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // this.seeRequestedCourses();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(msg);
  };

  getSignedUpCourses = (studentId: number) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getSignedUpCoursesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCoursesForAccountApiEndpoint.replace(
        ':account_id',
        studentId,
      ),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSignedUpCoursesMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStudentList = (courseId: number) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getStudentListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentListByCourseIdApiEndpoint.replace(
        ':course_id',
        courseId,
      ),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const loginToken: string = message.getData(
        getName(MessageEnum.SessionResponseToken),
      );
      runEngine.debugLog('TOKEN', loginToken);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData)),
      );
      const studentId: number = messageData?.meta?.id;
      this.setState({ studentId });
      if (loginToken) {
        this.setState({ loginToken }, () => this.getSignedUpCourses(studentId));
      }
    }

    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
    if (
      restApiDataCondition &&
      this.getSignedUpCoursesApiCallId != null &&
      this.getSignedUpCoursesApiCallId === apiRequestCallId
    ) {
      this.setState({
        signedUpCourses: responseJson.data,
      });
    }
    if (
      restApiDataCondition &&
      this.getStudentListApiCallId != null &&
      this.getStudentListApiCallId === apiRequestCallId
    ) {
      if (responseJson?.data) {
        this.setState({
          studentList: responseJson.data,
        });
      }
    }
  }

  navigateToViewAllCourses = () => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PerformanceTrackerBasicUserAllCoursesScreen',
    );

    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      accountId: this.state.studentId,
      signedUpCourses: this.state.signedUpCourses,
    });
    requestMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage,
    );

    this.send(requestMessage);
  };

  navigateToViewTests = (signedUpCourse: ICourse) => {
    const requestMessage = new Message(getName(MessageEnum.NavigationMessage));
    requestMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'PerformanceTrackerTestScoresForStudentScreen',
    );

    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      courseId: signedUpCourse.attributes.id,
      studentId: this.state.studentId,
    });
    requestMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage,
    );

    this.send(requestMessage);
  };

  setViewStudentsModalOpen = (signedUpCourse: ICourse) => {
    this.getStudentList(signedUpCourse.attributes.id);
    this.setState({
      studentsOnCourseModalVisible: true,
    });
  };

  hideViewStudentsModal = () => {
    this.setState({
      studentsOnCourseModalVisible: false,
    });
  };
  // Customizable Area End
}
