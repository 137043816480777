import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Modal,
} from '@material-ui/core';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ICourse, IStudent } from './types/types';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
// Customizable Area End

import PerformanceTrackerBasicUserCoursesScreenController, {
  Props,
  configJSON,
} from './PerformanceTrackerBasicUserCoursesScreenController';

export default class PerformanceTrackerBasicUserCoursesScreen extends PerformanceTrackerBasicUserCoursesScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSignedUpCourses = () => {
    const { signedUpCourses } = this.state;
    return (
      <>
        <Button
          data-test-id={'viewAllCourses'}
          title={'View All Courses'}
          variant="contained"
          onClick={() => this.navigateToViewAllCourses()}
        >
          {configJSON.viewAllCoursesText}
        </Button>
        <Table>
          <TableHead>
            <TableCell> {configJSON.courseNameText}</TableCell>
            <TableCell>{configJSON.descriptionText}</TableCell>
            <TableCell>{configJSON.viewTestsText}</TableCell>
            <TableCell>{configJSON.viewStudentListText}</TableCell>
          </TableHead>
          <TableBody>
            {signedUpCourses.length > 1 &&
              signedUpCourses?.map((signedUpCourse: ICourse, index: number) => (
                <TableRow key={`signedupcourse-${index}`}>
                  <TableCell>{signedUpCourse.attributes.name}</TableCell>
                  <TableCell>{signedUpCourse.attributes.description}</TableCell>
                  <TableCell>
                    {signedUpCourse.attributes.approved ? (
                      <Button
                        data-test-id={`viewTestsButton${index}`}
                        title={'View Tests'}
                        variant="contained"
                        onClick={() => this.navigateToViewTests(signedUpCourse)}
                      >
                        {configJSON.viewTestsText}
                      </Button>
                    ) : (
                      `${configJSON.awaitingApprovalText}`
                    )}
                  </TableCell>
                  <TableCell>
                    {signedUpCourse.attributes.approved ? (
                      <>
                        <Button
                          data-test-id={`viewAllStudentsButton${index}`}
                          title={'View All Students'}
                          variant="contained"
                          onClick={() =>
                            this.setViewStudentsModalOpen(signedUpCourse)
                          }
                        >
                          {configJSON.viewAllStudentsText}
                        </Button>
                        <Box data-test-id={`studentList${index}`}>
                          {this.renderViewStudentsForCourseModal()}
                        </Box>
                      </>
                    ) : (
                      `${configJSON.awaitingApprovalText}`
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </>
    );
  };

  renderViewStudentsForCourseModal = () => {
    const { studentList } = this.state;
    return (
      <Modal
        open={this.state.studentsOnCourseModalVisible}
        onClose={this.hideViewStudentsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={webStyle.modal}
      >
        <>
          <Box>
            {studentList?.map((student: IStudent, index: number) => (
              <Box key={`student-${index}`}>
                <Typography>
                  {configJSON.studentIdText}
                  {student?.attributes?.account_id}
                </Typography>
              </Box>
            ))}
          </Box>
          <Button
            data-test-id={'btnCloseModal'}
            onClick={() => {
              this.hideViewStudentsModal();
            }}
          >
            {configJSON.closeText}
          </Button>
        </>
      </Modal>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={'md'}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div"></Typography>
            {this.renderSignedUpCourses()}
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: 'flex',
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    width: '100%',
    height: '45px',
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)',
  },
  modal: {
    backgroundColor: 'lightblue',
    alignSelf: 'center',
    alignItems: 'center',
    width: '75%',
    justifyContent: 'space-between',
    margin: '2em',
  },
};
// Customizable Area End
