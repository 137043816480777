import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { IExam, IStudent } from './types/types';
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loginToken: string;
  courseExams: IExam[];
  createTestModalVisible: boolean;
  addTestScoreModalVisible: boolean;
  courseId: string;
  testName: string;
  credits: string;
  passPercentage: string;
  studentList: IStudent[];
  currentStudent: IStudent | null;
  newTestScore: string;
  currentTest: IExam | null;
  currentStudentId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PerformanceTrackerTestsForCourseScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCourseExamsApiCallId: string = '';
  deleteTestApiCallId: string = '';
  createTestApiCallId: string = '';
  getStudentListApiCallId: string = '';
  changeTestScoreApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loginToken: '',
      courseExams: [],
      createTestModalVisible: false,
      addTestScoreModalVisible: false,
      courseId: '',
      currentTest: null,
      currentStudent: null,
      testName: '',
      credits: '',
      passPercentage: '',
      studentList: [],
      newTestScore: '',
      currentStudentId: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(msg);
  };

  // GET tests for course by id
  viewCourseExams = (courseId: number | string) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getCourseExamsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCourseExamsByCourseIdApiEndpoint.replace(
        ':course_id',
        courseId,
      ),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // GET student list in the course
  getStudentList = (courseId: number | string) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getStudentListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentListByCourseIdApiEndpoint.replace(
        ':course_id',
        courseId,
      ),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // POST test score
  changeTestScore = (
    account_id: number,
    test_id: string | number,
    scorePercentage: string,
  ) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const body = {
      performance_test_id: Number(test_id),
      score_percentage: Number(scorePercentage),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.changeTestScoreApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTestScoreApiEndpoint.replace(':account_id', account_id),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createUpdateMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // POST create test
  createTest = (
    course_id: string,
    nameInput: string,
    creditsInput: string,
    passPercentage: string,
  ) => {
    if (
      !course_id ||
      !nameInput ||
      !creditsInput ||
      !passPercentage ||
      course_id === '' ||
      creditsInput === '' ||
      passPercentage === ''
    ) {
      this.showAlert('Please fill in all fields', '');
      this.hideCreateTestModal();
      return;
    }
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const body = {
      performance_test: {
        name: nameInput,
        performance_tracker_course_id: Number(course_id),
        credits: Number(creditsInput),
        pass_percentage: Number(passPercentage),
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.createTestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTestApiEndpoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createUpdateMethodType,
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // DELETE test
  deleteTest = (test_id: number) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.deleteTestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteTestApiEndpoint.replace(':test_id', test_id),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const loginToken: string = message.getData(
        getName(MessageEnum.SessionResponseToken),
      );
      runEngine.debugLog('TOKEN', loginToken);
      this.setState({ loginToken });
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const courseData = message.getData(
        getName(MessageEnum.SessionResponseData),
      );
      const courseId = courseData?.courseId;
      if (courseId) {
        this.setState({ courseId }, () => this.viewCourseExams(courseId));
        this.getStudentList(courseId);
      }
    }
    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
    // GET course exams
    if (
      restApiDataCondition &&
      this.getCourseExamsApiCallId != null &&
      this.getCourseExamsApiCallId === apiRequestCallId
    ) {
      this.setState({
        courseExams: responseJson.data,
      });
    }
    // delete test
    if (
      restApiDataCondition &&
      this.deleteTestApiCallId != null &&
      this.deleteTestApiCallId === apiRequestCallId
    ) {
      this.showAlert('Exam deleted', '');
      this.viewCourseExams(this.state.courseId);
    }
    // GET student list
    if (
      restApiDataCondition &&
      this.getStudentListApiCallId != null &&
      this.getStudentListApiCallId === apiRequestCallId
    ) {
      this.setState({
        studentList: responseJson?.data,
        currentStudent: responseJson?.data[0] ?? null,
        currentStudentId: responseJson?.data[0].attributes.account_id + '',
      });
    }
    //POST create test
    if (
      restApiDataCondition &&
      this.createTestApiCallId != null &&
      this.createTestApiCallId === apiRequestCallId
    ) {
      this.hideCreateTestModal();
      this.viewCourseExams(this.state.courseId);
    }
    //POST test score
    if (
      restApiDataCondition &&
      this.changeTestScoreApiCallId != null &&
      this.changeTestScoreApiCallId === apiRequestCallId
    ) {
      this.hideAddTestScoreModal();
    }
  }
  showCreateTestModal = () => {
    this.setState({ createTestModalVisible: true });
  };

  hideCreateTestModal = () => {
    this.setState({ createTestModalVisible: false });
  };
  showAddTestScoreModal = (exam: IExam) => {
    this.setState({ addTestScoreModalVisible: true, currentTest: exam });
  };

  hideAddTestScoreModal = () => {
    this.setState({ addTestScoreModalVisible: false });
  };

  handleTestNameChange = (testName: string) => this.setState({ testName });

  handleCreditsChange = (credits: string) => this.setState({ credits });

  handlePassPercentageChange = (passPercentage: string) =>
    this.setState({ passPercentage });

  handleCurrentStudentChange = (currentStudent: IStudent) => {
    if (currentStudent) this.setState({ currentStudent });
  };

  handleCurrentStudentIdChange = (currentStudentId: string) => {
    if (currentStudentId) this.setState({ currentStudentId });
  };

  handleNewTestScoreChange = (newTestScore: string) =>
    this.setState({ newTestScore });
  // Customizable Area End
}
