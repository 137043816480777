import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { IExam } from './types/types';
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loginToken: string;
  courseId: string;
  accountId: number;
  testScores: IExam[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PerformanceTrackerTestScoresForStudentScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getScoresForAccountApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loginToken: '',
      courseId: '',
      accountId: -1,
      testScores: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage),
    );
    this.send(msg);
  };

  getScoresForAccount = (account_id: number) => {
    const headers = {
      'Content-Type': configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.getScoresForAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getScoresForAccountApiEndpoint.replace(
        ':account_id',
        account_id,
      ),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSignedUpCoursesMethodType,
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage),
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage),
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const loginToken: string = message.getData(
        getName(MessageEnum.SessionResponseToken),
      );
      runEngine.debugLog('TOKEN', loginToken);
      this.setState({ loginToken });
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const studentData = message.getData(
        getName(MessageEnum.SessionResponseData),
      );
      const { studentId, courseId } = studentData;
      if (studentId && courseId) {
        this.setState({ accountId: studentId, courseId }, () =>
          this.getScoresForAccount(studentId),
        );
      }
    }
    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getScoresForAccountApiCallId != null &&
      this.getScoresForAccountApiCallId === apiRequestCallId
    ) {
      if (responseJson.data) {
        this.setState({
          testScores: responseJson.data,
        });
      }
    }
  }
  // Customizable Area End
}
