// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Matchalgorithm2 from "../../blocks/matchalgorithm2/src/Matchalgorithm2";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Documentstorage from "../../blocks/documentstorage/src/Documentstorage";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import FullTeamVisibility from "../../blocks/fullteamvisibility/src/FullTeamVisibility";
import Contexttagging from "../../blocks/contexttagging/src/Contexttagging";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Guestlogin2 from "../../blocks/guestlogin2/src/Guestlogin2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import DocumentOpener from "../../blocks/documentopener/src/DocumentOpener";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import Chatbot from "../../blocks/chatbot/src/Chatbot";
import Sorting from "../../blocks/sorting/src/Sorting";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Archive from "../../blocks/archive/src/Archive";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Teambuilder2 from "../../blocks/teambuilder2/src/Teambuilder2";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker";
import PerformanceTrackerCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerCoursesScreen";
import PerformanceTrackerTestsForCourseScreen from "../../blocks/performancetracker/src/PerformanceTrackerTestsForCourseScreen";
import PerformanceTrackerStudentsOnCourseScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentsOnCourseScreen";
import PerformanceTrackerTestScoresForStudentScreen from "../../blocks/performancetracker/src/PerformanceTrackerTestScoresForStudentScreen";
import PerformanceTrackerBasicUserCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerBasicUserCoursesScreen";
import PerformanceTrackerBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PerformanceTrackerBasicUserAllCoursesScreen";
import PerformanceTrackerStudentRankingScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentRankingScreen";
import PerformanceTrackerStudentInfoScreen from "../../blocks/performancetracker/src/PerformanceTrackerStudentInfoScreen";
import Analytics from "../../blocks/analytics/src/Analytics";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfrolespermission4 from "../../blocks/cfrolespermission4/src/Cfrolespermission4";



const routeMap = {
Customisableuserprofiles2:{
 component:Customisableuserprofiles2,
path:"/Customisableuserprofiles2"},
Matchalgorithm2:{
 component:Matchalgorithm2,
path:"/Matchalgorithm2"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Documentstorage:{
 component:Documentstorage,
path:"/Documentstorage"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
FullTeamVisibility:{
 component:FullTeamVisibility,
path:"/FullTeamVisibility"},
Contexttagging:{
 component:Contexttagging,
path:"/Contexttagging"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Guestlogin2:{
 component:Guestlogin2,
path:"/Guestlogin2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
DocumentOpener:{
 component:DocumentOpener,
path:"/DocumentOpener"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
Chatbot:{
 component:Chatbot,
path:"/Chatbot"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Archive:{
 component:Archive,
path:"/Archive"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Chat:{
 component:Chat,
path:"/Chat"},
ChatView:{
 component:ChatView,
path:"/ChatView"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Teambuilder2:{
 component:Teambuilder2,
path:"/Teambuilder2"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
PerformanceTrackerCoursesScreen:{
 component:PerformanceTrackerCoursesScreen,
path:"/PerformanceTrackerCoursesScreen"},
PerformanceTrackerTestsForCourseScreen:{
 component:PerformanceTrackerTestsForCourseScreen,
path:"/PerformanceTrackerTestsForCourseScreen"},
PerformanceTrackerStudentsOnCourseScreen:{
 component:PerformanceTrackerStudentsOnCourseScreen,
path:"/PerformanceTrackerStudentsOnCourseScreen"},
PerformanceTrackerTestScoresForStudentScreen:{
 component:PerformanceTrackerTestScoresForStudentScreen,
path:"/PerformanceTrackerTestScoresForStudentScreen"},
PerformanceTrackerBasicUserCoursesScreen:{
 component:PerformanceTrackerBasicUserCoursesScreen,
path:"/PerformanceTrackerBasicUserCoursesScreen"},
PerformanceTrackerBasicUserAllCoursesScreen:{
 component:PerformanceTrackerBasicUserAllCoursesScreen,
path:"/PerformanceTrackerBasicUserAllCoursesScreen"},
PerformanceTrackerStudentRankingScreen:{
 component:PerformanceTrackerStudentRankingScreen,
path:"/PerformanceTrackerStudentRankingScreen"},
PerformanceTrackerStudentInfoScreen:{
 component:PerformanceTrackerStudentInfoScreen,
path:"/PerformanceTrackerStudentInfoScreen"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Multilevelapproval2:{
 component:Multilevelapproval2,
path:"/Multilevelapproval2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfrolespermission4:{
 component:Cfrolespermission4,
path:"/Cfrolespermission4"},

  Home: {
component:MobileAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
