Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";

exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.labelTitleText = "PerformanceTracker";
exports.labelBodyText = "PerformanceTracker Body";

exports.getSignedUpCoursesMethodType = "GET";
exports.getCoursesForAccountApiEndpoint =  "/tracker/accounts/:account_id/courses?approved=true&include_scores=true";

exports.getStudentListByCourseIdApiEndpoint = "/tracker/courses/:course_id/accounts";

exports.getAllAvailableCoursesApiEndpoint = "/tracker/performance_tracker_courses";

exports.registerForCourseMethodType = "POST";
exports.requestCourseSignUpApiEndpoint = "/tracker/accounts/:account_id/course_registration";

exports.getCourseExamsByCourseIdApiEndpoint = "/tracker/courses/:course_id/tests";


// Admin endpoints
exports.createUpdateMethodType = "POST";
exports.createUpdateCourseApiEndpoint = "/tracker/performance_tracker_courses";
exports.createTestApiEndpoint = "/tracker/performance_tests";
exports.updateTestScoreApiEndpoint = "/tracker/accounts/:account_id/tests_scores";
//course approve
exports.registerForCourseAdminApiEndpoint = "/courses/:course_id/approve";
exports.approveCourseAdminApiEndpoint = "/tracker/performance_tracker_courses/:course_id/approve";

//GET for Admin
exports.retrieveExamScoresByAccountIdApiEndpoint = "/accounts/:account_id/courses?include_scores=:include_scores&approved=:is_approved&enabled=:is_enabled";
exports.getAccountsForCourseIdApiEndpoint = "/tracker/courses/:course_id/accounts";
exports.getScoresForAccountApiEndpoint = "/tracker/accounts/:account_id/scores";
exports.getPerformanceRankingsApiEndpoint = "/tracker/performance/rankings";

//DELETE for admin
exports.deleteMethodType = "DELETE";
exports.deleteCourseApiEndpoint = "/tracker/courses/:id";
exports.deleteTestApiEndpoint = "/tracker/tests/:test_id";
exports.deleteTestFromAccountApiEndpoint = "/accounts/:account_id/test_scores/:test_id";
exports.deleteAccountFromCourseApiEndpoint = "/accounts/:account_id/courses/:course_id/deregister";

//labels
exports.viewStudentInfoText = "View Student Info";
exports.rankingText = "Ranking: ";
exports.studentNameText = "Student Name";
exports.gpaText = "GPA: ";
exports.studentIdText = "Student ID: "
exports.viewCoursesText = "View Courses";
exports.viewStudentsText = "View Students";

exports.createCourseText = "Create Course";

exports.viewAllCoursesText="View All Courses";
exports.courseNameText = "Course Name: ";
exports.descriptionText="Description: ";
exports.courseIdText = "Course Id: ";
exports.idText = " Id:";
exports.registrationStatusText = "Registration Status";
exports.viewCourseTestsText = "View Course Tests";
exports.viewTestsText="View Tests";
exports.viewStudentsText = "View Students";
exports.deleteCourseText = "Delete Course";
exports.viewStudentListText = "View Student List";
exports.studentListText = "Student List";
exports.awaitingApprovalText = "Awaiting Approval";
exports.disabledText = "Disabled";
exports.registeredText = "Registered";
exports.viewAllStudentsText = "View All Students";
exports.closeText = "Close";

exports.registerForCourseText = "Register for Course";

exports.nameText = "Name: ";
exports.testNameText = "Test Name: ";
exports.creditsText = "Credits: ";
exports.testCreditsText = "Test Credits: ";
exports.passPercentageText = "Pass Percentage: ";
exports.studentScoreText = "Student Score: ";
exports.scorePercentageText = "Score Percentage: ";

exports.studentAccountIdText = "Student Account Id: ";
exports.approvedText = "Approved: ";
exports.approveStudentText = "Approve Student?";
exports.viewTestScoresText = "View Test Scores";
exports.approveText = "Approve";

exports.createTestText = "Create Test";
exports.examNameText = "Exam Name: ";
exports.examIdText = "Exam Id: ";
exports.addChangeTestScoreText = "Add/Change Test Score";
exports.deleteTestText = "Delete Test";

exports.selectStudentIdText = "Select Student ID";
exports.newTestScoreText = "New Test Score: ";
exports.changeTestScoreText = "Change Test Score";

exports.testIdText = "Test Id: "
exports.studentStatusText = "Student Status";
// Customizable Area End